import * as React from "react";
import * as styles from "./about-us.module.css";

import {
  Box,
  Button,
  Grid,
  HStack,
  Heading,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";

import ContentBox from "../components/ContentBox";
import { FunctionComponent } from "react";
import HeroContainer from "../components/hero-container";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";
import contentBox from "../constants/contentBox";
import useAuth from "../components/useAuth";

const AboutUs: FunctionComponent = () => {
  const { isBankUser, isCloudTalkUser, isOtherBankUser } = useAuth();

  return (
    <>
      <Layout>
        <div className={styles.aboutus}>
          <HeroContainer
            heroImage="/dm-aboutus-hero.webp"
            heroTitle="About Us"
            sectionDescription="DoctorMedica.co stocks a wide variety of cosmetic and orthopedic injectables, as well as creams, peels, and other aesthetic products, there may be times you cannot find a specific product you are looking for."
            arrowImage="/remixiconslinesystemarrowdownsline4.svg"
            heroBackgroundPadding="var(--padding-9xl) 0rem"
            heroSubTitle={""} // url="/about-us/#about"
          />
          <Box
            id="anchorlink"
            mx="auto"
            maxW="1280px"
            pt={0}
            pb={10}
            px={{ base: 4, md: 0 }}
          >
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
              }}
              gap={0}
              rowGap={5}
            >
              {contentBox.map((box, index) => (
                <ContentBox
                  key={index}
                  id={box.id}
                  src={box.src}
                  alt={box.alt}
                  title={box.title}
                  text={box.text}
                />
              ))}

              {/* Address - Conditionally displayed */}
              {isBankUser && (
                <Box>
                  <Box display="flex" alignItems="center" mb={4}>
                    <Image src="/mail9.svg" alt="address" w={6} h={6} />
                    <Text ml={3} fontWeight="bold" fontSize="xl">
                      Address
                    </Text>
                  </Box>
                  <Text>
                    STRREAM DIRECT MARKETING LTD <br />
                    Registration Number 2426117 <br />
                    Registered office 2540 Matheson Blvd East, Unit 202,
                    Mississauga, ON L4W4Z2, Canada
                  </Text>
                </Box>
              )}
              {isOtherBankUser && (
                <Box>
                  <Box display="flex" alignItems="center" mb={4}>
                    <Image src="/mail9.svg" alt="address" w={6} h={6} />
                    <Text ml={3} fontWeight="bold" fontSize="xl">
                      Address
                    </Text>
                  </Box>
                  <Text>
                    STRREAM DIRECT MARKETING LTD <br />
                    30 Eglinton Ave West, Unit 710 <br />
                    Mississauga, ON Canada, L5R3E7
                  </Text>
                </Box>
              )}

              {isCloudTalkUser && (
                <Box>
                  <Box display="flex" alignItems="center" mb={4}>
                    <Image
                      src="/mail9.svg"
                      alt="European Address"
                      w={6}
                      h={6}
                    />
                    <Text ml={3} fontWeight="bold" fontSize="xl">
                      European Address
                    </Text>
                  </Box>
                  <Text>
                    <b>STRREAM DIRECT MARKETING EUROPE</b>
                    <br />
                    Company Address: Andrea Miaouli, 2A, 4006, Limassol, Cyprus.
                    <br />
                    Company registration number: ΗΕ 430209
                    <br />
                    Website:{" "}
                    <Link href="http://www.strreamdirect.com/" color="blue.500">
                      www.strreamdirect.com
                    </Link>
                    <br />
                    Director Name: Eleftherios Eleftheriou
                    <br />
                    Company Email address:{" "}
                    <Link
                      href="mailto:europe.office@strreamdirect.com"
                      color="blue.500"
                    >
                      europe.office@strreamdirect.com
                    </Link>
                  </Text>
                </Box>
              )}
            </Grid>
          </Box>
          <Box
            w="100%"
            px="20px"
            pt={{ base: "40px", md: "0" }}
            backgroundImage="/unlock-savings.webp"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            h={{ base: "100%", md: "480px" }}
          >
            <Box maxW="1280px" m="0px auto">
              <HStack
                justifyContent="space-around"
                flexFlow={{ base: "column", md: "row" }}
              >
                <VStack alignItems="flex-start">
                  <Box bg="#120B0C" width="24px" height="2px"></Box>
                  <Text
                    as="span"
                    pt={6}
                    color="#1A212D"
                    fontSize="md"
                    fontWeight={600}
                    lineHeight="24px"
                  >
                    Do you need help or more information?
                  </Text>
                  <Heading
                    color="#D28C49"
                    w={{ base: "343px", md: "600px" }}
                    fontSize={{ base: "30px !important", md: "36px" }}
                    lineHeight="150%"
                    fontWeight={400}
                    pb={8}
                  >
                    Book a Consultation {""}
                    <Text as="span" color="#262626">
                      {" "}
                      with our sales representative.
                    </Text>
                  </Heading>
                  <Link
                    href="https://talktodoctormedica.youcanbook.me/"
                    target="_blank"
                    w={{ base: "100%", md: "263px" }}
                  >
                    <Button
                      fontSize="sm"
                      w="100%"
                      fontWeight={700}
                      color="#120B0C"
                      variant="outline"
                      textTransform={"uppercase"}
                    >
                      BOOK A CONSULTATION
                    </Button>
                  </Link>
                </VStack>
                <Image
                  src="/about-contact.webp"
                  alt="Image of a consultant"
                  w={{ base: "343px", md: "600px" }}
                  h={{ base: "260px", md: "480px" }}
                />
              </HStack>
            </Box>
          </Box>
        </div>
      </Layout>
    </>
  );
};

export const Head = () => (
  <SEO
    title="About Us | DoctorMedica.co"
    description="Learn more about Doctor Medica and how to buy brand name orthopedic and cosmetic medical supplies for your medical clinic at low wholesale prices."
  />
);

export default AboutUs;
